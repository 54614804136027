#main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

#app-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#app-icon img {
  width: 100%;
  height: 100%;
}

#app-name {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

#app-button-container {
  margin-top: 40px;
  width: 80%;
}

.app-button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
}

.open-app-button {
  background-color: black;
  color: #fff;
}

.download-app-button {
  background-color: #fff;
  color: black;
}

#app-info {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  width: 80%;
}

#app-info p {
  padding-bottom: 10px;
}
